import * as React from 'react';

export const HomeIcon = () => {
  return (
    <span className="block px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-900 md:p-0">
      <span className="block md:hidden">Home</span>
      <svg
        className="hidden md:block"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V9.75025L12.0002 4.75024L4.75024 9.75025V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
        ></path>
      </svg>
    </span>
  );
};

export const FacebookButton = () => {
  return (
    <a
      className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-900 md:p-0"
      href="https://www.facebook.com/gray.mae.charcuterie/"
      title="Gray & Mae Facebook Page"
    >
      <span className="block md:hidden">Facebook</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="hidden md:block"
      >
        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
      </svg>
    </a>
  );
};

export const InstagramButton = () => {
  return (
    <a
      className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-900 md:p-0"
      href="https://www.instagram.com/gray.mae.charcuterie"
      title="Gray & Mae Instagram Account"
    >
      <span className="block md:hidden">Instagram</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="hidden md:block"
      >
        <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
      </svg>
    </a>
  );
};
