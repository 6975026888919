import '@fontsource/sue-ellen-francisco';
import { PageProps, StaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import { Query } from 'src/graphql-types';

import { DesktopNav } from './nav/DesktopNav';
import { MobileNav } from './nav/MobileNav';

export const query = graphql`
  query NavQuery {
    allSanityNavigation(filter: { navId: { current: { eq: "menu_items" } } }) {
      nodes {
        id
        items {
          id
          slug {
            current
          }
          name
        }
      }
    }
  }
`;

interface HeaderProps {
  location?: PageProps['location'];
}

export const Header = ({ location }: HeaderProps) => {
  return (
    <StaticQuery
      query={query}
      render={(data: Query) => {
        const items = data.allSanityNavigation.nodes[0].items;

        return (
          <>
            <div className="flex justify-center">
              <header
                className="flex flex-col items-center w-full md:hidden"
                style={{ maxWidth: 1200 }}
              >
                <MobileNav items={items} />
              </header>
              <header
                className="flex-row items-center hidden w-full py-2 pl-3 xl:px-0 md:flex"
                style={{ maxWidth: 1200 }}
              >
                <DesktopNav items={items} />
              </header>
            </div>
          </>
        );
      }}
    />
  );
};
