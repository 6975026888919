import { defaultSerializers } from '@sanity/block-content-to-react';
import React from 'react';

export const serializers = {
  types: {
    blurb: (props: any) => {
      if (props.node.listItem === 'bullet') {
        return defaultSerializers.listItem(props);
      }

      return defaultSerializers.types.block(props);
    },
    list: (props: any) => {
      const { type } = props;
      const bullet = type === 'bullet';
      if (bullet) {
        return <ul>{props.children}</ul>;
      }
      return <ol>{props.children}</ol>;
    },
    listItem: (props: any) => <li>{props.children}</li>,
  },
};
