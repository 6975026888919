import { Link } from 'gatsby';
import React from 'react';
import {
  FacebookButton,
  HomeIcon,
  InstagramButton,
} from 'src/components/Icons';
import { StyledLink } from 'src/components/StyledLink';
import { NAV_ITEMS } from 'src/constants';
import { SanityNavigation } from 'src/graphql-types';

interface MobileNavProps {
  items: SanityNavigation['items'];
}

export function MobileNav({ items }: MobileNavProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const links = React.useMemo(() => {
    return NAV_ITEMS.map(({ page, title }) => {
      return (
        <StyledLink key={page} to={page}>
          {title}
        </StyledLink>
      );
    });
  }, []);

  const products = React.useMemo(() => {
    return items.map(({ id, slug, name }) => {
      return (
        <StyledLink to={`/product/${slug.current}`} key={id}>
          {name}
        </StyledLink>
      );
    });
  }, [items]);

  return (
    <>
      <div className="flex w-full">
        <button
          className="relative z-20 w-full px-4 py-2 text-sm font-medium text-gray-500 border-b border-gray-200 hover:text-gray-900"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? 'Hide' : 'Show'} Menu
        </button>
        <Link
          to="/corporate-events"
          className="relative z-20 block w-full px-4 py-2 text-sm font-medium text-center text-gray-500 border-b border-l border-gray-200 hover:text-gray-900"
          title="Corporate &amp; Events"
        >
          Corporate & Events
        </Link>
      </div>
      {isOpen && (
        <nav className="flex flex-col w-full border-b border-gray-200 bg-gray-50">
          <Link to="/" title="Go to Home Page">
            <HomeIcon />
          </Link>
          <hr />
          {products}
          <hr />
          {links}

          <FacebookButton />
          <InstagramButton />
        </nav>
      )}
    </>
  );
}
