import '@fontsource/sue-ellen-francisco';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { Header } from './components/Header';
import { FacebookButton, InstagramButton } from './components/Icons';
import { StyledLink } from './components/StyledLink';
import { NAV_ITEMS } from './constants';
import logo from './images/logo.svg';

export const Hero = () => {
  return (
    <div className="relative items-center justify-center hidden overflow-hidden bg-gray-800 md:h-hero h-80 md:flex">
      <div className="relative z-10 w-48 mt-0 md:w-hero md:-mt-24">
        <img src={logo} alt="Gray + Mae Logo" />
      </div>
      <StaticImage
        src="./images/hero.webp"
        className="opacity-25"
        style={{ position: 'absolute' }}
        alt=""
      />
    </div>
  );
};

export const Footer = () => {
  const links = React.useMemo(() => {
    return NAV_ITEMS.map(({ page, title }) => {
      return (
        <StyledLink key={page} to={page}>
          {title}
        </StyledLink>
      );
    });
  }, []);

  return (
    <div className="relative flex items-center justify-center">
      <div
        className="relative flex flex-col w-full py-10 m-0 text-sm text-center md:mt-10 md:flex-row md:px-4 md:py-5 md:text-left"
        style={{ maxWidth: 1160 }}
      >
        {links}

        <div className="flex flex-col w-full pr-0 ml-0 space-x-0 md:space-x-6 md:pr-4 md:flex-row md:w-auto md:ml-auto">
          <FacebookButton />
          <InstagramButton />
        </div>
      </div>
    </div>
  );
};

interface LayoutProps {
  children: React.ReactNode;
  location?: PageProps['location'];
  title?: string;
}

const Layout = ({ children, title, location }: LayoutProps) => {
  let titleString = 'Gray + Mae';

  if (title) {
    titleString += ` • ${title}`;
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <title>{titleString}</title>
        <meta
          name="description"
          content="Perfectly curated charcuterie boxes that are not only delicious but great conversation pieces!"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>
      <Header location={location} />
      <Hero />
      <div className="relative z-10 flex justify-center mt-0 md:-mt-24 content">
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
