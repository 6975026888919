import { Link } from 'gatsby';
import React, { useMemo } from 'react';
import { SanityNavigation } from 'src/graphql-types';

interface MenuNavItemProps {
  items: SanityNavigation['items'];
}

export function MenuNavItem({ items }: MenuNavItemProps) {
  const links = useMemo(() => {
    return items.map(({ id, slug, name }) => {
      return (
        <Link
          className="pb-2 text-sm text-gray-700 border-b border-gray-100 last:border-0 last:p-0 hover:text-gray-900"
          to={`/product/${slug.current}`}
          key={id}
        >
          {name}
        </Link>
      );
    });
  }, [items]);

  return (
    <div
      className="flex items-center cursor-pointer group"
      style={{ height: 36 }}
    >
      <span className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-900 group-hover:text-gray-900">
        Boxes
      </span>
      <div
        className="absolute top-0 z-10 invisible p-4 bg-white shadow-md group-hover:visible hover:visible dropdown"
        style={{ top: 48 }}
      >
        <nav className="flex flex-col space-y-2">{links}</nav>
      </div>
    </div>
  );
}
