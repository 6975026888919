import { GatsbyLinkProps, Link } from 'gatsby';
import React, { ReactChild } from 'react';

interface Props extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  children: ReactChild;
}

export const StyledLink = ({ children, ...theRest }: Props) => {
  return (
    <Link
      className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-900"
      activeClassName="border-gray-200 text-gray-900"
      {...theRest}
    >
      {children}
    </Link>
  );
};
