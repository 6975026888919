import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import {
  FacebookButton,
  HomeIcon,
  InstagramButton,
} from 'src/components/Icons';
import { StyledLink } from 'src/components/StyledLink';
import { MenuNavItem } from 'src/components/nav/MenuNavItem';
import { NAV_ITEMS } from 'src/constants';
import { SanityNavigation } from 'src/graphql-types';

interface DesktopNavProps {
  items: SanityNavigation['items'];
}

export function DesktopNav({ items }: DesktopNavProps) {
  const links = React.useMemo(() => {
    return NAV_ITEMS.map(({ page, title }) => {
      return (
        <StyledLink key={page} to={page}>
          {title}
        </StyledLink>
      );
    });
  }, []);

  return (
    <>
      <Link to="/" className="mr-4" title="Go to Home Page">
        <HomeIcon />
      </Link>
      <LinkGroup>
        <MenuNavItem items={items} />

        {links}
      </LinkGroup>
      <div className="flex pr-4 ml-0 space-x-6 xl:p-0 md:ml-auto">
        <FacebookButton />
        <InstagramButton />
      </div>
    </>
  );
}

function LinkGroup({ children }: { children: ReactNode }) {
  return (
    <nav className="flex flex-col items-center w-full space-x-2 md:w-auto md:flex-row">
      {children}
    </nav>
  );
}
